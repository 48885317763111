import React, { useContext, useState } from "react";
import { Grid, Menu } from "react-feather";
import { Link } from "react-router-dom";
import { Image } from "../../AbstractElements";
import LumiRXIcon from "../../assets/images/logo/logo.png";
import CustomizerContext from "../../_helper/Customizer";

const SidebarLogo = () => {
  const { mixLayout, toggleSidebar, layout, layoutURL } =
    useContext(CustomizerContext);
  const [toggle, setToggle] = useState(false);

  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(toggle);
  };

  const redirectToDashboard = () => {
    window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
    // window.location.reload(true);
  };

  const layout1 = localStorage.getItem("sidebar_layout") || layout;

  return (
    <div className="logo-wrapper">
      {layout1 !== "compact-wrapper dark-sidebar" &&
      layout1 !== "compact-wrapper color-sidebar" &&
      mixLayout ? (
        // <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
        //   <Image
        //     attrImage={{
        //       className: "img-fluid d-inline",
        //       src: `${LumiRXIcon}`,
        //       alt: "",
        //     }}
        //   />
        // </Link>
        <button onClick={redirectToDashboard} style={{
          border: 'none',
          padding: 0,
          margin: 0,
          background: 'none',
          cursor: 'pointer',
          // Add any other styles you need
        }}>
          <img
            className="img-fluid d-inline"
            src={LumiRXIcon}
            alt=""
          />
        </button>
      ) : (
        // <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
        //   <Image
        //     attrImage={{
        //       className: "img-fluid d-inline",
        //       src: `${require("../../assets/images/logo/logo_dark.png")}`,
        //       alt: "",
        //     }}
        //   />
        // </Link>
        <button onClick={redirectToDashboard} style={{
          border: 'none',
          padding: 0,
          margin: 0,
          background: 'none',
          cursor: 'pointer',
          // Add any other styles you need
        }}>
          <img
            className="img-fluid d-inline"
            src= {`${require("../../assets/images/logo/logo_dark.png")}`}
            alt=""
          />
        </button>
      )}
      <div className="back-btn" onClick={() => openCloseSidebar()}>
        <i className="fa fa-angle-left"></i>
      </div>
      <div className="toggle-sidebar" onClick={openCloseSidebar}>
        <Menu className="status_toggle middle sidebar-toggle" />
      </div>
    </div>
  );
};

export default SidebarLogo;
