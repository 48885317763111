import React, { Fragment, useContext, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import { Link } from "react-router-dom";
import { AddNewDistributor } from "../../../../Constant";
import DistributorTableData from "./DistributorTableData";
import { PlusCircle } from "react-feather";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CustomizerContext from "../../../../_helper/Customizer";

const DistributorListContain = () => {
  const { layoutURL } = useContext(CustomizerContext);

  return (
    <Fragment>
      <Breadcrumbs
        parent="Seller"
        title="Seller List"
        mainTitle="Seller List"
      />
      <Container fluid={true}>
        <Row className="seller-card">
          <Col md="12" className="seller-list">
            <Card>
              <Row>
                <Col md="12">
                  <div className="text-end">
                    <Link
                      className="btn btn-primary"
                      style={{ color: "white" }}
                      to={`${process.env.PUBLIC_URL}/seller/add-seller`}
                    >
                      {" "}
                      <PlusCircle />
                      {AddNewDistributor}
                    </Link>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {/* <HeaderCard title={ProductListTitle} span1={ProductListDesc} /> */}
              <CardBody>
                <DistributorTableData />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default DistributorListContain;
