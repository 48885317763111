import { distributorProductColumns } from "../../../../Data/Ecommerce/ProductList";
import React, {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import {
  deleteProduct,
  deleteProductByDistributor,
  getAllProduct,
  getProductByManufacturer,
} from "../../../../helpers";
import { toast } from "react-toastify";
import { Btn, H6, Image } from "../../../../AbstractElements";
import { useNavigate } from "react-router";
import CustomizerContext from "../../../../_helper/Customizer";
import { FormGroup, Input, Label } from "reactstrap";
import Loader from "../../../../Layout/Loader";
import SweetAlert from 'sweetalert2';

const style = {
  width: 40,
  height: 40,
};
const style2 = { width: 60, fontSize: 14, padding: 4 };
const DistributorProductTableData = ({
  manufacturerId,
  onProductSelect = () => {},
  refreshComp = false,
}) => {
  const { layoutURL } = useContext(CustomizerContext);
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange = ({ selectedRows }) => {
    onProductSelect(selectedRows);
  };

  const navigation = useNavigate();
  useEffect(() => {
    if (manufacturerId.value || refreshComp) {
      getProductByManufacturerApi(manufacturerId.value);
    }
  }, [manufacturerId.value, refreshComp]);

  let onPressDelete = (prodData) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this product from your list!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
        if (result.value) {
          let params = {
            productId: prodData._id,
            // distributorId: parseInt(localStorage.getItem("distributorId")),
            sellerId: parseInt(localStorage.getItem("distributorId")),
            manufacturerId: prodData.manufacturerId,
          };

          console.log("params", params);

          deleteProductByDistributor(params)
            .then((data) => {
              if (data.success) {
                // toast.success(data.message);
                SweetAlert.fire(
                    'Deleted!',
                    'Product has been deleted.',
                    'success'
                );
                setTimeout(() => {
                  getProductByManufacturerApi(manufacturerId.value);
                }, 1000);
              } else {
                toast.error(data.response.data.message);
              }
            })
            .catch((error) => {
              toast.error(error);
            });  
        }
    });
  };

  const getProductByManufacturerApi = async (id) => {
    try {
      setLoading(true);
      getProductByManufacturer(id)
        .then((data) => {
          if (data.success) {
            let newData = data.data.map((d) => {
              return {
                image: (
                  <Image
                    attrImage={{
                      src: d.productImage,
                      style: style,
                      alt: "",
                    }}
                  />
                ),
                Details: (
                  <div>
                    <H6>{d?.name}</H6>
                  </div>
                ),
                id: d?._id,
                status: (
                  <div>
                    {d.productStatus === "approve" ? (
                      <div style={{ color: "green" }}>Approved</div>
                    ) : d.productStatus === "notAdded" ? (
                      <div style={{ color: "red" }}>Not added</div>
                    ) : d.productStatus === "decline" ? (
                      <div style={{ color: "red" }}>Decline</div>
                    ) : (
                      <div style={{ color: "orange" }}>Pending</div>
                    )}
                  </div>
                ),
                code: <div>{d?.productCode}</div>,
                amount: (d?.price != null || d?.price != 'null') ?  "$" + d?.price : "-",

                action: (
                  <div>
                    {d.productStatus != 'notAdded' && (<span>
                      &nbsp;&nbsp;
                      <Btn
                        attrBtn={{
                          style: style2,
                          color: "danger",
                          className: "btn btn-xs",
                          type: "button",
                          onClick: () => onPressDelete(d),
                        }}
                      >
                        Delete
                      </Btn>
                    </span>)}
                  </div>
                ),
                id: d?._id,
                isProductVerifiedRequired: d?.isProductVerifiedRequired,
              };
            });
            setProductData(newData);
          } else {
            toast.error(data.response.data.message);
          }
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <Fragment>
      <div className="table-responsive product-table">
        {loading ? (
          // <Loader />
          <Suspense fallback={<Loader />}></Suspense>
        ) : (
          <DataTable
            noHeader
            pagination
            columns={distributorProductColumns}
            data={productData}
            highlightOnHover={true}
            striped={true}
            responsive={true}
            selectableRows
            onSelectedRowsChange={handleCheckboxChange}
          />
        )}
      </div>
    </Fragment>
  );
};
export default DistributorProductTableData;
