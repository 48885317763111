import apis from "./Api";

//Auth api
export function adminLogin(credentials) {
  return apis
    .post(`/admin/login`, credentials)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function contactUsSendEmail(data) {
  return apis
    .post(`/master/contact/sendmail`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function getGtin(brandId) {
  return apis
    .get(`/master/gtin/${brandId}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function addProduct(productData) {
  let formData = new FormData();
  for (let key in productData) {
    formData.append(key, productData[key]);
  }
  return apis
    .post(`/master/addProduct`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}
export function editProduct(productData, id) {
  let formData = new FormData();
  for (let key in productData) {
    formData.append(key, productData[key]);
  }
  return apis
    .put(`/master/editProduct/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function getProductCategory() {
  return apis
    .get(`/master/getAllCategory`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function getBrandByCategory(categoryId) {
  return apis
    .get(`/master/getBrandByCategory/${categoryId}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function addBrand(brandData) {
  return apis
    .post(`/master/addBrand`, brandData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}
export function editBrand(editData, id) {
  return apis
    .put(`/master/editBrand/${id}`, editData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function deleteBrand(id) {
  return apis
    .delete(`/master/deleteBrand/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function getAllBrands() {
  return apis
    .get(`/master/getAllBrand`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function getAllProduct(params) {
  return apis
    .get(`/master/getAllProduct`, { params: params })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function deleteProduct(categoryId) {
  return apis
    .delete(`/master/deleteProduct/${categoryId}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function getAccount() {
  return apis
    .get(`/master/account`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function getVerifyProductHistory() {
  return apis
    .get(`/master/verifyProductHistory`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function updateAccount(data) {
  const headers = {
    "Content-Type": "multipart/form-data", // Set your desired content type here
  };
  return apis
    .put(`/master/account`, data, { headers })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function getAllManufacturer(params) {
  return apis
    .get(`/manufacturer/getAllManufacturer`, { params: params })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function addManufacturer(manufacturerData) {
  return apis
    .post(`/manufacturer/addManufacturer`, manufacturerData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function deleteManufacturer(manufacturerId) {
  return apis
    .delete(`/manufacturer/deleteManufacturer/${manufacturerId}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}
export function manufacturerLogin(credentials) {
  return apis
    .post(`/manufacturer/login`, credentials)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function editManufacturer(editData, id) {
  return apis
    .put(`/manufacturer/editManufacturer/${id}`, editData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function distributorLogin(credentials) {
  return (
    apis
      // .post(`/seller/login`, credentials)
      .post(`/seller/login`, credentials)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        checkError(error);
        return error;
      })
  );
}

export function getAllDistributor(params) {
  return (
    apis
      // .get(`/seller/getAllDistributor`, {params: params})
      .get(`/seller/getAllSeller`, { params: params })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        checkError(error);
        return error;
      })
  );
}

export function addDistributor(distributorData) {
  return (
    apis
      // .post(`/seller/addDistributor`, distributorData)
      .post(`/seller/addSeller`, distributorData)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        checkError(error);
        return error;
      })
  );
}
export function deleteDistributor(id) {
  return (
    apis
      // .delete(`/seller/deleteDistributor/${id}`)
      .delete(`/seller/deleteSeller/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        checkError(error);
        return error;
      })
  );
}

export function editDistributor(editData, id) {
  return (
    apis
      // .put(`/seller/editDistributor/${id}`, editData)
      .put(`/seller/editSeller/${id}`, editData)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        checkError(error);
        return error;
      })
  );
}

export function getProductByManufacturer(id) {
  return apis
    .get(`/master/getProductByManufacturer/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}
export function getProductVerifyRequest(params) {
  return apis
    .get(`/master/getProductVerifyRequest`, { params: params })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}
export function actionOnDistributorRequest(id, data) {
  return apis
    .post(`/manufacturer/approveProduct/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}
export function sendProductVerifyRequest(id, data) {
  console.log("data", data);
  return apis
    .post(`/master/sendProductVerifyRequest/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      checkError(error);
      return error;
    });
}

export function deleteProductByDistributor(data) {
  console.log("data", data);
  return (
    apis
      // .post(`/master/deleteProductByDistributor`, data)
      .post(`/master/deleteProductBySeller`, data)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        checkError(error);
        return error;
      })
  );
}

export function checkError(error) {
  if (error?.response?.status == 403) {
    localStorage.removeItem("profileURL");
    localStorage.removeItem("access_token");
    localStorage.removeItem("login");
    localStorage.removeItem("auth0_profile");
    localStorage.removeItem("Name");
    localStorage.removeItem("role");
    localStorage.removeItem("manufacturerId");
    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  }
}
