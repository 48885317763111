import React, { Fragment, useContext } from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import { Link } from "react-router-dom";
import { AddNewManufacturer } from "../../../../Constant";
import ManufacturerTableData from "./ManufacturerTableData";
import { PlusCircle } from "react-feather";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CustomizerContext from "../../../../_helper/Customizer";

const ManufacturerListContain = () => {
  const { layoutURL } = useContext(CustomizerContext);
  return (
    <Fragment>
      <Breadcrumbs
        parent="Brand"
        title="Brand List"
        mainTitle="Brand List"
      />
      <Container fluid={true}>
        <Row className="manufacturer-card">
          <Col md="12" className="manufacturer-list">
            <Card>
              <Row>
                <Col md="12">
                  <div className="text-end">
                    <Link
                      className="btn btn-primary"
                      style={{ color: "white" }}
                      to={`${process.env.PUBLIC_URL}/manufacturer/add-manufacturer`}
                    >
                      {" "}
                      <PlusCircle />
                      {AddNewManufacturer}
                    </Link>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {/* <HeaderCard title={ProductListTitle} span1={ProductListDesc} /> */}
              <CardBody>
                <ManufacturerTableData />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ManufacturerListContain;
