import React, { Fragment, useState, useEffect, useContext } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P } from "../AbstractElements";
import {
  EmailAddress,
  ForgotPassword,
  Password,
  RememberPassword,
  SignIn,
} from "../Constant";

import { useNavigate } from "react-router-dom";
import man from "../assets/images/dashboard/profile.png";
import webLogo from "../assets/images/web-logo.png";

import CustomizerContext from "../_helper/Customizer";
import OtherWay from "./Tabs/LoginTab/OtherWay";
import { ToastContainer, toast } from "react-toastify";
import { adminLogin, manufacturerLogin } from "../helpers";

const ManufacturerSignin = ({ selected }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));

  // useEffect(() => {}, [value, name]);

  let validation = () => {
    let emailReg =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!emailReg.test(email)) {
      toast.error("Please enter valid email.");
      return false;
    } else if (password.length < 8) {
      toast.error(
        "Your password is not strong enough. Use at least 8 characters"
      );

      return false;
    }
    return true;
  };

  const loginAuth = async (e) => {
    //Api
    if (validation()) {
      let body = {
        email: email,
        password: password,
      };
      manufacturerLogin(body)
        .then((data) => {
          console.log("data", data);
          if (data.success) {
            localStorage.setItem("access_token", data.access_token);
            localStorage.setItem("login", true);
            localStorage.setItem("role", data.data.role);
            localStorage.setItem("manufacturerId", data.data.id);
            localStorage.setItem("email", data.data.email);
            localStorage.setItem("Name", data.data.name);
            localStorage.setItem("profileURL", data.data.profile_picture);

            toast.success(data.message);
            setTimeout(() => {
              history(`${process.env.PUBLIC_URL}/dashboard`);
            }, 1000);
          } else {
            toast.error(data.response.data.message);
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div>
                <img
                  src={webLogo}
                  alt="web-logo"
                  style={{ margin: 31, marginLeft: 170 }}
                />
                <div className="login-main login-tab">
                  <Form className="theme-form">
                    <H4>
                      {selected === "simpleLogin"
                        ? "Sign In With Simple Login"
                        : "Sign Into Brand Account"}
                    </H4>
                    <P>{"Enter your email & password to login"}</P>
                    <FormGroup>
                      <Label className="col-form-label">{EmailAddress}</Label>
                      <Input
                        className="form-control"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </FormGroup>
                    <FormGroup className="position-relative">
                      <Label className="col-form-label">{Password}</Label>
                      <div className="position-relative">
                        <Input
                          className="form-control"
                          type={togglePassword ? "text" : "password"}
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                        />
                        <div
                          className="show-hide"
                          onClick={() => setTogglePassword(!togglePassword)}
                        >
                          <span className={togglePassword ? "" : "show"}></span>
                        </div>
                      </div>
                    </FormGroup>
                    <div className="position-relative form-group mb-0">
                      <div className="checkbox">
                        <Input id="checkbox1" type="checkbox" />
                        <Label className="text-muted" for="checkbox1">
                          {RememberPassword}
                        </Label>
                      </div>

                      <Btn
                        attrBtn={{
                          color: "primary",
                          className: "d-block w-100 mt-2 mb-3",
                          onClick: (e) => loginAuth(e),
                        }}
                      >
                        {SignIn}
                      </Btn>
                      <span
                        style={{ color: "#E36F6F", cursor: "pointer" }}
                        onClick={() => {
                          history("/login");
                        }}
                      >
                        Admin login
                      </span>
                      <br />
                      <span
                        style={{ color: "#E36F6F", cursor: "pointer" }}
                        onClick={() => {
                          history("/seller/login");
                        }}
                      >
                        Seller login
                      </span>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default ManufacturerSignin;
