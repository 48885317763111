import React from 'react';
import { Btn, H6 } from '../../AbstractElements';

const style = {
  width: 60,
  height: 60,
};
const style2 = { width: 60, fontSize: 14, padding: 4 };
export const distributorData = [
  {
    // image: <Image attrImage={{ src: product1, style: style, alt: '' }} />,
    name: (<H6>Brooklyn Simmons</H6>),
    email: "brookly.simmons@yahoo.com",
    phone: "+1 56-6566-6566",
    action: (
      <div>
        <span>
          <Btn attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' }}>Edit </Btn>
        </span>
        &nbsp;&nbsp;
        <span>
          <Btn attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button' }}>Delete</Btn>
        </span>{' '}
      </div>
    ),
  },
  {
    // image: <Image attrImage={{ src: product1, style: style, alt: '' }} />,
    name: (<H6>Mark Jecno</H6>),
    email: "mark.jecno@gmail.com",
    phone: "+1 55-7854-5896",
    action: (
      <div>
        <span>
          <Btn attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' }}>Edit </Btn>
        </span>
        &nbsp;&nbsp;
        <span>
          <Btn attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button' }}>Delete</Btn>
        </span>{' '}
      </div>
    ),
  },
  {
    // image: <Image attrImage={{ src: product1, style: style, alt: '' }} />,
    name: (<H6>Dev John</H6>),
    email: "dev.john@yahoo.com",
    phone: "+1 99-4577-8796",
    action: (
      <div>
        <span>
          <Btn attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' }}>Edit </Btn>
        </span>
        &nbsp;&nbsp;
        <span>
          <Btn attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button' }}>Delete</Btn>
        </span>{' '}
      </div>
    ),
  },
];
export const distributorColumns = [
  // {
  //   name: 'Image',
  //   selector: (row) => row.image,
  //   sortable: true,
  //   center: true,
  //   minWidth: '100px',
  //   maxWidth: '100px',
  // },
  {
    name: 'Name',
    selector: (row) => row.name,
    sortable: true,
    center: false,
    wrap: true,
    minWidth: '200px',
  },
  {
    name: 'Email',
    selector: (row) => row.email,
    sortable: true,
    center: true,
    minWidth: '300px',
    maxWidth: '150px',
  },
  {
    name: 'Phone',
    selector: (row) => row.phone,
    sortable: true,
    center: false,
    minWidth: '200px',
    maxWidth: '150px',
  },
  {
    name: 'Action',
    selector: (row) => row.action,
    sortable: true,
    center: true,
    minWidth: '160px',
    maxWidth: '160px',
  },
];
