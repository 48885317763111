import React, { Fragment, useContext, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { Btn } from "../../../../AbstractElements";
import Select from "react-select";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import CustomizerContext from "../../../../_helper/Customizer";
import { addDistributor, editDistributor } from "../../../../helpers";
import { Loader } from "react-feather";

const AddDistributor = () => {
  const navigation = useNavigate();
  let location = useLocation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  let [loader, setLoader] = useState(false);
  let validation = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;

    if (name.length === 0) {
      toast.error("Please enter a name.");
      return false;
    }

    if (email.length === 0) {
      toast.error("Please enter an email address.");
      return false;
    }

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return false;
    }

    if (location.state != null) {
      return true;
    } else {
      if (password.length < 8) {
        toast.error("Please enter a strong password.");
        return false;
      }
    }

    if (phone.length === 0) {
      toast.error("Please enter a phone number.");
      return false;
    }
    if (!phoneRegex.test(phone)) {
      toast.error("Please enter a valid phone number.");
      return false;
    }

    return true;
  };

  const onSubmit = () => {
    if (validation()) {
      setLoader(true);
      let params = {
        name: name,
        email: email,
        password: password,
        phone: phone,
      };
      if (location.state == null) {
        addDistributor(params)
          .then((data) => {
            console.log("data::", data);
            if (data?.success) {
              toast.success(data.message);
              setTimeout(() => {
                navigation("/seller/seller-list");
              }, 1000);
            } else {
              setTimeout(() => {
                setLoader(false);
              }, 1000);
              toast.error(data.response.data.message);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoader(false);
            }, 1000);
            toast.error(err);
          });
      } else {
        let editParams = {
          name: name,
          email: email,
          password: password.length > 0 ? password : null,
          phone: phone,
        };

        editDistributor(editParams, location.state?.id)
          .then((data) => {
            if (data?.success) {
              navigation("/seller/seller-list");
              toast.success(data.message);
            } else {
              setTimeout(() => {
                setLoader(false);
              }, 1000);
              toast.error(data.response.data.message);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoader(false);
            }, 1000);
            toast.error(err);
          });
      }
    }
  };

  useEffect(() => {
    if (location.state != null) {
      setDefaultValue();
    }
  }, []);

  let setDefaultValue = () => {
    let data = location.state;
    setName(data.name);
    setEmail(data.email);
    setPhone(data.phone);
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="Seller"
        title={location.state != null ? "Edit Seller" : "Add New Seller"}
        mainTitle={location.state != null ? "Edit Seller" : "Add New Seller"}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {/* <Form className='theme-form' id="add_product_form" noValidate='' onSubmit={handleSubmit(onSubmit)}> */}
                <Form className="theme-form" id="add_product_form">
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label for="distributor_name">Name</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="distributor_name"
                          placeholder="Enter Full Name"
                          id="distributor_name"
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          value={name}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="distributor_email">Email</Label>
                        <InputGroup>
                          <InputGroupText>{"@"}</InputGroupText>
                          <Input
                            className="form-control"
                            type="email"
                            name="distributor_email"
                            placeholder="Enter Email"
                            id="distributor_email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />

                          <div className="valid-feedback">Looks good!</div>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="distributor_password">Password</Label>
                        <Input
                          className="form-control"
                          type="password"
                          name="distributor_password"
                          placeholder="Password enter here"
                          id="distributor_password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label for="distributor_phone">Phone</Label>
                        <InputGroup>
                          <InputGroupText>
                            <i className="icofont icofont-ui-dial-phone"></i>
                          </InputGroupText>
                          <Input
                            className="form-control"
                            type="email"
                            name="distributor_email"
                            placeholder="1-(000)-000-000"
                            id="distributor_phone"
                            maxLength={10}
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                          />

                          <div className="valid-feedback">Looks good!</div>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Btn
                    attrBtn={{
                      color: "primary",
                      onClick: onSubmit,
                      disabled: loader,
                    }}
                  >
                    {loader ? (
                      <Spinner size="sm">Loading...</Spinner>
                    ) : location.state != null ? (
                      "Edit Seller"
                    ) : (
                      "Add Seller"
                    )}
                  </Btn>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddDistributor;
