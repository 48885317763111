import { distributorRequestProductColumns } from "../../../../Data/Ecommerce/ProductList";
import React, {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import {
  deleteProduct,
  deleteProductByDistributor,
  getAllProduct,
  getProductByManufacturer,
  getProductVerifyRequest,
} from "../../../../helpers";
import { toast } from "react-toastify";
import { Breadcrumbs, Btn, H6, Image } from "../../../../AbstractElements";
import { useNavigate } from "react-router";
import CustomizerContext from "../../../../_helper/Customizer";
import { FormGroup, Input, Label } from "reactstrap";
import { actionOnDistributorRequest } from "../../../../helpers/service";
import Loader from "../../../../Layout/Loader";
import SweetAlert from 'sweetalert2';

const style = {
  width: 40,
  height: 40,
};
const style2 = { fontSize: 14, padding: 4 };
const DistributorRequestTableData = ({ onProductSelect = () => {} }) => {
  const { layoutURL } = useContext(CustomizerContext);
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange = ({ selectedRows }) => {
    onProductSelect(selectedRows);
  };

  const navigation = useNavigate();
  useEffect(() => {
    getProductByManufacturerApi();
  }, []);
  let requestAction = (id, status) => {
    if(status == 'approve'){
      actionDistributor(id, status);
    }
    if(status == 'decline'){
      SweetAlert.fire({
        title: 'Are you sure?',
        text: 'Once declined, you will not be able to recover this request!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
          if (result.value) {
            actionDistributor(id, status);
          }
      });
    }
  };

  const actionDistributor = (id, status) =>{
    actionOnDistributorRequest(id, {
      status,
    })
      .then((data) => {
        if (data.success) {
          console.log("this is called",status);
          
          if(status == 'decline'){
            SweetAlert.fire(
              'Declined!',
              'Seller request for product has been declined.',
              'success'
            );
          }else{
              SweetAlert.fire(
                'Approved!',
                'Seller request for product has been approved.',
                'success'
              );
          }
          setTimeout(() => {
            getProductByManufacturerApi();
          }, 1000);
          // toast.success(data.message);
        } else {
          toast.error(data.response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }
  const getProductByManufacturerApi = async () => {
    try {
      setLoading(true);
      getProductVerifyRequest()
        .then((data) => {
          if (data.success) {
            let newData = data.data.map((d) => {
              return {
                image: (
                  <Image
                    attrImage={{
                      src: d.productImage,
                      style: style,
                      alt: "",
                    }}
                  />
                ),
                Details: (
                  <div>
                    <H6>{d?.name}</H6>
                  </div>
                ),
                distributorName: (
                  <div>
                    <H6>{d?.sellerName}</H6>
                  </div>
                ),
                id: d?._id,
                status: (
                  <div>
                    {d.productStatus === 'approve' ? (
                      <div style={{ color: "green" }}>Approved</div>
                    ) : d.productStatus === 'decline' ? (
                      <div style={{ color: "red" }}>Declined</div>
                    ) : (
                      <div style={{ color: "orange" }}>Requested</div>
                    )}
                    {/* {!d.isProductVerifiedRequired ? (
                      <div style={{ color: "green" }}>Approved</div>
                    ) : (
                      <div style={{ color: "orange" }}>Requested</div>
                    )} */}
                  </div>
                ),
                code: (
                  <div>
                    <H6>{d?.productCode}</H6>
                  </div>
                ),
                amount: "$" + d?.price,

                action: (
                  <div>
                    {d.productStatus == 'pending' ? (
                      <>
                        <span>
                          <Btn
                            attrBtn={{
                              style: style2,
                              color: "success",
                              className: "btn btn-xs",
                              type: "button",
                              onClick: () => {
                                requestAction(d._id, "approve");
                              },
                            }}
                          >
                            Approve
                          </Btn>
                        </span>
                        &nbsp;&nbsp;
                        <span>
                          <Btn
                            attrBtn={{
                              style: style2,
                              color: "danger",
                              className: "btn btn-xs",
                              type: "button",
                              onClick: () => {
                                requestAction(d._id, "decline");
                              },
                            }}
                          >
                            Decline
                          </Btn>
                        </span>
                      </>
                    ) : (<></>
                      // <span
                      //   style={{
                      //     backgroundColor: d.productStatus === 'approve' ? 'green' : 'red',
                      //     color: 'white',
                      //     padding: '5px',
                      //     fontWeight: 'bold',
                      //   }}
                      // >
                      //   {d.productStatus}
                      // </span>
                    )}
                  </div>
                ),
                id: d?._id,
                isProductVerifiedRequired: d?.isProductVerifiedRequired,
              };
            });
            setProductData(newData);
          } else {
            toast.error(data.response.data.message);
          }
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <Fragment>
      <Breadcrumbs
        parent="Seller"
        title="Seller Request Products List"
        mainTitle="Seller Request Products List"
      />
      <div className="table-responsive product-table">
        {loading ? (
          // <Loader />
          <Suspense fallback={<Loader />}></Suspense>
        ) : (
          <DataTable
            noHeader
            pagination
            columns={distributorRequestProductColumns}
            data={productData}
            highlightOnHover={true}
            striped={true}
            responsive={true}
            onSelectedRowsChange={handleCheckboxChange}
          />
        )}
      </div>
    </Fragment>
  );
};
export default DistributorRequestTableData;
