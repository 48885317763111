import {
  manufacturerColumns,
  manufacturerData,
} from "../../../../Data/ManufacturerDistributor/ManufacturerList";
import React, {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import { getAllManufacturer, deleteManufacturer } from "../../../../helpers";
import { toast } from "react-toastify";
import { Btn, H6, Image } from "../../../../AbstractElements";
import { useNavigate, useLocation } from "react-router";
import CustomizerContext from "../../../../_helper/Customizer";
import Loader from "../../../../Layout/Loader";
import { Search } from "react-feather";
import SweetAlert from 'sweetalert2';
import TableCustomPagination from "../../Ecommerce/ProductList/TableCustomPagination";

const style = {
  width: 40,
  height: 40,
};
const style2 = { width: 60, fontSize: 14, padding: 4 };

const ManufacturerTableData = () => {
  const { layoutURL } = useContext(CustomizerContext);

  const [manufacturerData, setManufacturerData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState("");
  
  const [pageIndex, setPageIndex] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalManufacturers, setTotalManufacturers] = useState(0);

  const navigation = useNavigate();

  useEffect(() => {
    setLoading(true);
    getAllManufacturerApi(pageIndex, limit, searchText);
  }, [pageIndex, limit]);

  let onPressEdit = (data) => {
    navigation(`/manufacturer/edit-manufacturer`, {
      state: data,
    });
  };
  let onPressDelete = (venderData) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this brand!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
        if (result.value) {
          deleteManufacturer(venderData.id)
            .then((data) => {
              if (data.success) {
                SweetAlert.fire(
                    'Deleted!',
                    'Brand has been deleted.',
                    'success'
                );
                setTimeout(() => {
                  getAllManufacturerApi(pageIndex, limit, searchText);
                }, 1000);
              } else {
                toast.error(data.response.data.message);
              }
            })
            .catch((error) => {
              toast.error(error);
            }); 
        }
    });
  };

  const getAllManufacturerApi = async (pageIndex, limit, searchText) => {
    try {
      await getAllManufacturer({pageIndex, limit, searchText})
        .then((data) => {
          if (data.success) {
            let newData = data.data.map((d) => {
              return {
                name: <H6>{d.name}</H6>,
                email: d.email,
                phone: d.phone,
                action: (
                  <div>
                    <span>
                      <Btn
                        attrBtn={{
                          style: style2,
                          color: "success",
                          className: "btn btn-xs",
                          type: "button",
                          onClick: () => onPressEdit(d),
                        }}
                      >
                        Edit{" "}
                      </Btn>
                    </span>
                    &nbsp;&nbsp;
                    <span>
                      <Btn
                        attrBtn={{
                          style: style2,
                          color: "danger",
                          className: "btn btn-xs",
                          type: "button",
                          onClick: () => onPressDelete(d),
                        }}
                      >
                        Delete
                      </Btn>
                    </span>{" "}
                  </div>
                ),
              };
            });
            setManufacturerData(newData);
            setTotalManufacturers(data.totalManufacturers)
            setLoading(false);
          } else {
            toast.error(data.response.data.message);
          }
        })
        .catch((error) => {
          toast.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSearch = (e) =>{
    e.preventDefault();
    console.log("searchText = ",searchText);
    getAllManufacturerApi(pageIndex, limit, searchText);
  }

  const handlePageChange = (page,rowsPerPage) => {
    console.log("handlePageChange")
    setPageIndex(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    console.log("handleRowsPerPageChange")
    setLimit(currentRowsPerPage);
    setPageIndex(currentPage);
  };

  return (
    <Fragment>
      <div className="table-responsive manufacturer-table">
        {loading ? (
          // <Loader />
          <Suspense fallback={<Loader />}></Suspense>
        ) : (
          <>
            <div className="tbl_search">
              <input type="text" placeholder="Search..." value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
              <button className="search-btn btn btn-primary" onClick={(e)=>handleSearch(e)}>
                <Search />
              </button>
            </div>
            <DataTable
              noHeader
              columns={manufacturerColumns}
              data={manufacturerData}
              highlightOnHover={true}
              striped={true}
              responsive={true}
              // pagination
              // paginationPerPage={limit}
              // onChangePage={handlePageChange}
              // onChangeRowsPerPage={handleRowsPerPageChange}
              // paginationServer
              // paginationTotalRows={totalManufacturers}
              // paginationRowsPerPageOptions={[10, 20, 30]}
            />
            <TableCustomPagination
              totalItems={totalManufacturers}
              itemsPerPage={limit}
              currentPage={pageIndex}
              onPageChange={handlePageChange}
              onItemsPerPageChange={handleRowsPerPageChange}
            />
          </>
        )}
      </div>
    </Fragment>
  );
};
export default ManufacturerTableData;
