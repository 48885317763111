import axios from "axios";
// export const URL = "https://lumirx.kretosstechnology.com/api/v1";
export const URL = "https://api.lumirx.com/api/v1";
// export const URL = "https://lumi.netfiy.com/api/v1";
// export const URL = "http://localhost:3009/api/v1";

// export const imageURL = "http://127.0.0.1:3009/public/product/";
// export const imageURL = "https://lumirx.kretosstechnology.com/public/product/";
export const imageURL = "https://api.lumirx.com/public/product/";

let authToken = "";

// Check if localStorage is available before using it
if (typeof localStorage !== "undefined") {
  authToken = localStorage.getItem("access_token");
  console.log("authToken", authToken);
}

const axiosInstance = axios.create({
  baseURL: URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${authToken}`,
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve token from localStorage before each request
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
