import React, { Fragment, useContext, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { Btn } from "../../../../AbstractElements";
import Select from "react-select";
import { toast } from "react-toastify";
import { addManufacturer } from "../../../../helpers";
import { useLocation, useNavigate } from "react-router";
import CustomizerContext from "../../../../_helper/Customizer";
import { editManufacturer } from "../../../../helpers/service";

const AddManufacturer = () => {
  const navigation = useNavigate();
  let location = useLocation();

  const [name, setName] = useState("");
  const [brandName, setBrandName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  let [loader, setLoader] = useState(false);

  let validation = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;

    if (name.length === 0) {
      toast.error("Please enter a name.");
      return false;
    }

    if (email.length === 0) {
      toast.error("Please enter an email address.");
      return false;
    }

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return false;
    }

    if (location.state != null) {
      return true;
    } else {
      if (password.length < 8) {
        toast.error("Please enter a strong password.");
        return false;
      }
    }

    if (phone.length === 0) {
      toast.error("Please enter a phone number.");
      return false;
    }
    if (!phoneRegex.test(phone)) {
      toast.error("Please enter a valid phone number.");
      return false;
    }

    return true;
  };

  const onSubmit = () => {
    if (validation()) {
      setLoader(true);

      let params = {
        name: name,
        email: email,
        password: password,
        phone: phone,
        brand: brandName
      };
      if (location.state == null) {
        addManufacturer(params)
          .then((data) => {
            console.log("data::", data);
            if (data?.success) {
              toast.success(data.message);
              setTimeout(() => {
                navigation("/manufacturer/manufacturer-list");
              }, 1000);
            } else {
              setTimeout(() => {
                setLoader(false);
              }, 1000);
              toast.error(data.response.data.message);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoader(false);
            }, 1000);
            toast.error(err);
          });
      } else {
        let editParams = {
          name: name,
          email: email,
          password: password.length > 0 ? password : null,
          phone: phone,
          brand: brandName
        };
        console.log("edit manufacturer called");
        console.log("params", editParams);
        editManufacturer(editParams, location.state?.id)
          .then((data) => {
            if (data?.success) {
              navigation("/manufacturer/manufacturer-list");
              toast.success(data.message);
            } else {
              setTimeout(() => {
                setLoader(false);
              }, 1000);
              toast.error(data.response.data.message);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoader(false);
            }, 1000);
            console.log(err, "this is error");
            toast.error(err);
          });
      }
    }
  };

  useEffect(() => {
    if (location.state != null) {
      setDefaultValue();
    }
  }, []);

  let setDefaultValue = () => {
    let data = location.state;
    setName(data.name);
    setEmail(data.email);
    setPhone(data.phone);
    setBrandName(data.brand ? data.brand : "");
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="Brand"
        title={location.state != null ? "Edit Brand" : "Add New Brand"}
        mainTitle={location.state != null ? "Edit Brand" : "Add New Brand"}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form className="theme-form" id="add_product_form">
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label for="manufacturer_name">Name</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="manufacturer_name"
                          placeholder="Enter Full Name"
                          id="manufacturer_name"
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          value={name}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="manufacturer_email">Email</Label>
                        <InputGroup>
                          <InputGroupText>{"@"}</InputGroupText>
                          <Input
                            className="form-control"
                            type="email"
                            name="manufacturer_email"
                            placeholder="Enter Email"
                            id="manufacturer_email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />

                          <div className="valid-feedback">Looks good!</div>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="manufacturer_password">Password</Label>
                        <Input
                          className="form-control"
                          type="password"
                          name="manufacturer_password"
                          placeholder="Password enter here"
                          id="manufacturer_password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label for="manufacturer_phone">Phone</Label>
                        <InputGroup>
                          <InputGroupText>
                            <i className="icofont icofont-ui-dial-phone"></i>
                          </InputGroupText>
                          <Input
                            className="form-control"
                            type="email"
                            name="manufacturer_email"
                            placeholder="1-(000)-000-000"
                            id="manufacturer_phone"
                            maxLength={10}
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                          />

                          <div className="valid-feedback">Looks good!</div>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    {/* <Col sm="6">
                      <FormGroup>
                        <Label for="manufacturer_brand_name">Brand</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="manufacturer_brand_name"
                          placeholder="Enter Brand Name"
                          id="manufacturer_brand_name"
                          onChange={(e) => {
                            setBrandName(e.target.value);
                          }}
                          value={brandName}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </FormGroup>
                    </Col> */}
                  </Row>

                  <Btn
                    attrBtn={{
                      color: "primary",
                      onClick: onSubmit,
                      disabled: loader,
                    }}
                  >
                    {loader ? (
                      <Spinner size="sm">Loading...</Spinner>
                    ) : location.state != null ? (
                      "Save"
                    ) : (
                      "Add Brand"
                    )}
                  </Btn>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddManufacturer;
