import { productColumns } from "../../../../Data/Ecommerce/ProductList";
import React, {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import { deleteProduct, getAllProduct } from "../../../../helpers";
import { toast } from "react-toastify";
import { Btn, H4, H6, Image } from "../../../../AbstractElements";
import { useNavigate } from "react-router";
import CustomizerContext from "../../../../_helper/Customizer";
import Loader from "../../../../Layout/Loader";
import { Search } from "react-feather";
import SweetAlert from 'sweetalert2';
// import CustomPagination from "../../../Bonus-Ui/Pagination/CustomPagination";
import TableCustomPagination from "./TableCustomPagination";

const style = {
  width: 40,
  height: 40,
};
const style2 = { width: 60, fontSize: 14, padding: 4 };
const ProductTableData = ({}) => {
  const { layoutURL } = useContext(CustomizerContext);
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [pageIndex, setPageIndex] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalProducts, setTotalProducts] = useState(0);

  const navigation = useNavigate();
  let onPressEdit = (data) => {
    navigation(`/edit-product`, {
      state: data,
    });
  };

  let onPressDelete = (prodData) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this product!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
        if (result.value) {
            deleteProduct(prodData._id)
            .then((data) => {
              if (data.success) {
                SweetAlert.fire(
                    'Deleted!',
                    'Product has been deleted.',
                    'success'
                );
                setTimeout(() => {
                  getAllProductApi(pageIndex, limit, searchText);
                }, 1000);
              } else {
                toast.error(data.response.data.message);
              }
            })
            .catch((error) => {
              toast.error(error);
            });    
        }
    });
  };

  const getAllProductApi = async (pageIndex, limit, searchText) => {
    try {
      await getAllProduct({pageIndex, limit, searchText})
        .then((data) => {
          if (data.success) {
            let newData = data.data.map((d) => {
              return {
                image: (
                  <Image
                    attrImage={{
                      src: d.productImage,
                      style: style,
                      alt: "",
                    }}
                  />
                ),
                Details: (
                  <div>
                    <H6>{d?.name}</H6>
                  </div>
                ),
                BrandName: (
                  <div>
                    <b>{d?.manufacturerName}</b>
                  </div>
                ),
                // manufacturer: (
                //   <div>
                //     <b>{d?.manufacturerName}</b>
                //   </div>
                // ),
                // code: (
                //   <div>
                //     <b>{d?.productCode}</b>
                //   </div>
                // ),
                amount: (d?.price == null) ? "-" : "$" + d?.price,

                action: (
                  <div>
                    <span>
                      <Btn
                        attrBtn={{
                          style: style2,
                          color: "success",
                          className: "btn btn-xs",
                          type: "button",
                          onClick: () => onPressEdit(d),
                        }}
                      >
                        Edit
                      </Btn>
                    </span>
                    &nbsp;&nbsp;
                    <span>
                      <Btn
                        attrBtn={{
                          style: style2,
                          color: "danger",
                          className: "btn btn-xs",
                          type: "button",
                          onClick: () => onPressDelete(d),
                        }}
                      >
                        Delete
                      </Btn>
                    </span>
                  </div>
                ),
              };
            });
            setProductData(newData);
            setTotalProducts(data.totalProducts);
            setLoading(false);
          } else {
            toast.error(data.response.data.message);
          }
        })
        .catch((err) => {
          toast.error(err);
        })
    } catch (error) {
      toast.error(error);
    }
  };
  const handleSearch = (e) =>{
    e.preventDefault();
    getAllProductApi(pageIndex, limit, searchText);
  }
  const handlePageChange = (page,rowsPerPage) => {
    console.log("handlePageChange",page)
    setPageIndex(page);
  };
  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    console.log("handleRowsPerPageChange",currentRowsPerPage)
    setLimit(currentRowsPerPage);
    setPageIndex(currentPage);
  };
  
  useEffect(()=>{
    setLoading(true);
    getAllProductApi(pageIndex, limit, searchText);
  },[pageIndex, limit])
  return (
    <Fragment>
      {loading ? (
        // <div style={{ width: 100, height: 100 }}>
        //   <Loader />
        // </div>
        <Suspense fallback={<Loader />}></Suspense>
      ) : (
        <div className="table-responsive product-table">
          <div className="tbl_search">
            <input type="text" placeholder="Search..." value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
            <button className="search-btn btn btn-primary" onClick={(e)=>handleSearch(e)}>
              <Search />
            </button>
          </div>
          <DataTable
            columns={productColumns}
            data={productData}
            highlightOnHover={true}
            striped={true}
            responsive={true}
            // pagination
            // paginationPerPage={limit}
            // onChangePage={handlePageChange}
            // onChangeRowsPerPage={handleRowsPerPageChange}
            // paginationServer
            // paginationTotalRows={totalProducts}
            // paginationRowsPerPageOptions={[10, 20, 30]}
            style={{display: 'none'}}
            center={true}
          />
          <TableCustomPagination
            totalItems={totalProducts}
            itemsPerPage={limit}
            currentPage={pageIndex}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleRowsPerPageChange}
          />
        </div>
      )}
    </Fragment>
  );
};
export default ProductTableData;
