
  import React, {
    Fragment,
    Suspense,
    useContext,
    useEffect,
    useState,
  } from "react";
  import DataTable from "react-data-table-component";
  import { deleteDistributor, getAllDistributor } from "../../../../helpers";
  import { toast } from "react-toastify";
  import { Btn, H6, Image } from "../../../../AbstractElements";
  import { useNavigate, useLocation } from "react-router";
  import CustomizerContext from "../../../../_helper/Customizer";
  import Loader from "../../../../Layout/Loader";
  import { Search } from "react-feather";
  import SweetAlert from 'sweetalert2';
  import TableCustomPagination from "../../Ecommerce/ProductList/TableCustomPagination";
import { deleteBrand, getAllBrands } from "../../../../helpers/service";
import { brandColumns } from "../../../../Data/ManufacturerDistributor/BrandList";
  
  const style = {
    width: 40,
    height: 40,
  };
  const style2 = { width: 60, fontSize: 14, padding: 4 };
  
  const BrandTableData = () => {
    const [brandData, setBrandData] = useState([]);
    const [loading, setLoading] = useState(false);
  
    const [searchText, setSearchText] = useState("");
  
    const navigation = useNavigate();
    const { layoutURL } = useContext(CustomizerContext);
  
    let onPressEdit = (data) => {
      navigation(`/brands/add-brand`, {
        state: data,
      });
    };
  
    let onPressDelete = (brandData) => {
      SweetAlert.fire({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this brand!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
          if (result.value) {
            deleteBrand(brandData._id)
              .then((data) => {
                if (data.success) {
                  SweetAlert.fire(
                      'Deleted!',
                      'Brand has been deleted.',
                      'success'
                  );
                  setTimeout(() => {
                    getAllBrandsApi();
                  }, 1000);
                } else {
                  toast.error(data.response.data.message);
                }
              })
              .catch((error) => {
                toast.error(error);
              }); 
          }
      });
    };
  
    useEffect(() => {
      setLoading(true);
      getAllBrandsApi();
    }, []);
  
    const getAllBrandsApi = () => {
      try {
        getAllBrands()
          .then((data) => {
            if (data.success) {
                console.log("-=-=-=",data)
              let newData = data.data.map((d) => {
                return {
                  name: <H6>{d.name}</H6>,
                  categoryName: <b>{d.categoryName}</b>,
                  action: (
                    <div>
                      <span>
                        <Btn
                          attrBtn={{
                            style: style2,
                            color: "success",
                            className: "btn btn-xs",
                            type: "button",
                            onClick: () => onPressEdit(d),
                          }}
                        >
                          Edit{" "}
                        </Btn>
                      </span>
                      &nbsp;&nbsp;
                      <span>
                        <Btn
                          attrBtn={{
                            style: style2,
                            color: "danger",
                            className: "btn btn-xs",
                            type: "button",
                            onClick: () => onPressDelete(d),
                          }}
                        >
                          Delete
                        </Btn>
                      </span>{" "}
                    </div>
                  ),
                };
              });
              setBrandData(newData);
              setLoading(false);
            } else {
              toast.error(data.response.data.message);
            }
          })
          .catch((error) => {
            toast.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        toast.error(error);
      }
    };
  
    const handleSearch = (e) =>{
      e.preventDefault();
      console.log("searchText = ",searchText);
    //   getAllBrandsApi(pageIndex, limit, searchText);
    }
  
    // const handlePageChange = (page,rowsPerPage) => {
    //   console.log("handlePageChange")
    //   setPageIndex(page);
    // };
  
    // const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    //   console.log("handleRowsPerPageChange")
    //   setLimit(currentRowsPerPage);
    //   setPageIndex(currentPage);
    // };
  
    return (
      <Fragment>
        <div className="table-responsive manufacturer-table">
          {loading ? (
            // <Loader />
            <Suspense fallback={<Loader />}></Suspense>
          ) : (
            <>
              {/* <div className="tbl_search">
                <input type="text" placeholder="Search..." value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
                <button className="search-btn btn btn-primary" onClick={(e)=>handleSearch(e)}>
                  <Search />
                </button>
              </div> */}
              <DataTable
                noHeader
                columns={brandColumns}
                data={brandData}
                highlightOnHover={true}
                striped={true}
                responsive={true}
              />
              {/* <TableCustomPagination
                totalItems={totalDistributors}
                itemsPerPage={limit}
                currentPage={pageIndex}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleRowsPerPageChange}
              /> */}
            </>
          )}
        </div>
      </Fragment>
    );
  };
  export default BrandTableData;
  