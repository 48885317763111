export const MENUITEMS = [
  {
    menutitle: "",
    menucontent: "Dashboards,Widgets",
    Items: [
      // {
      //   title: "LumiRX",
      //   icon: "home",
      //   type: "sub",
      //   badge: "badge badge-light-primary",
      //   badgetxt: "3",
      //   active: false,
      //   children: [
      //     { path: `${process.env.PUBLIC_URL}/dashboard/default`, title: "Default", type: "link" },
      //     // { path: `${process.env.PUBLIC_URL}/dashboard/e-commerce`, title: "E-commerce", type: "link" },
      //     // { path: `${process.env.PUBLIC_URL}/dashboard/online-course`, title: "Online Course", type: "link" },
      //     // { path: `${process.env.PUBLIC_URL}/dashboard/crypto`, title: "Crypto", type: "link" },
      //     // { path: `${process.env.PUBLIC_URL}/dashboard/social`, title: "Social", type: "link" },
      //   ],
      // },
      {
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: "home",
        title: "Dashboard",
        type: "link",
      },

      // { path: `${process.env.PUBLIC_URL}/add-product`, icon: "form", title: "Add-Product", type: "link" },
      {
        title: "Products",
        icon: "sample-page",
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/product-list`,
            title: "Product-List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/add-product`,
            icon: "form",
            title: "Add-Product",
            type: "link",
          },
        ],
      },
      {
        title: "Seller Products",
        icon: "sample-page",
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/seller-product-list`,
            title: "Seller-Product-List",
            type: "link",
          },
        ],
      },
      {
        title: "Seller Request",
        icon: "sample-page",
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/seller-request-list`,
            title: "Seller-Request-List",
            type: "link",
          },
        ],
      },
      {
        // title: "Manufacturer",
        title: "Brand",
        icon: "user",
        path: `${process.env.PUBLIC_URL}/users/profile`,
        type: "sub",
        bookmark: true,
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/manufacturer/manufacturer-list`,
            // title: "Manufacturer-List",
            title: "Brand-List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/manufacturer/add-manufacturer`,
            icon: "form",
            // title: "Add-Manufacturer",
            title: "Add-Brand",
            type: "link",
          },
        ],
      },
      // {
      //   title: "Brands",
      //   icon: "sample-page",
      //   path: `${process.env.PUBLIC_URL}/users/profile`,
      //   type: "sub",
      //   bookmark: true,
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/brands/brand-list`,
      //       title: "Brands-List",
      //       type: "link",
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/brands/add-brand`,
      //       icon: "form",
      //       title: "Add-Brand",
      //       type: "link",
      //     },
      //   ],
      // },
      {
        title: "Seller",
        icon: "user",
        path: `${process.env.PUBLIC_URL}/users/profile`,
        type: "sub",
        bookmark: true,
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/seller/seller-list`,
            title: "Seller-List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/seller/add-seller`,
            icon: "form",
            title: "Add-Seller",
            type: "link",
          },
        ],
      },
      {
        title: "Product Verify History",
        icon: "user",
        path: `${process.env.PUBLIC_URL}/users/profile`,
        type: "sub",
        bookmark: true,
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/history/product-verify-history-List`,
            title: "History-List",
            type: "link",
          },
          // {
          //   path: `${process.env.PUBLIC_URL}/seller/add-seller`,
          //   icon: "form",
          //   title: "Add-Seller",
          //   type: "link",
          // },
        ],
      },
      {
        path: `${process.env.PUBLIC_URL}/contact-us`,
        icon: "contact",
        title: "Contact us",
        type: "link",
      },
    ],
  },
];
