import React, { Fragment, useContext, useEffect, useState } from "react";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import { Link } from "react-router-dom";
import {
  ProductListDesc,
  ProductListTitle,
  CreateNewProduct,
} from "../../../../Constant";
import ProductTableData from "./ProductTableData";
import { PlusCircle } from "react-feather";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import HeaderCard from "../../../Common/Component/HeaderCard";
import CustomizerContext from "../../../../_helper/Customizer";
import DistributorProductTableData from "./DistributorProductTableData";
import Select from "react-select";
import { getAllManufacturer, sendProductVerifyRequest } from "../../../../helpers";
import { toast } from "react-toastify";

const ProductListContain = ({}) => {
  const { layoutURL } = useContext(CustomizerContext);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [selectManufacturer, setSelectManufacturer] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [refreshComp, setRefreshComp] = useState(false);

  useEffect(() => {
    getAllManufacturerApi();
  }, []);

  const sendApproveRequestApi = (id,isProductVerifiedRequired, productStatus = "pending") => {
    console.log("id", id);
    let params = {
      productStatus: productStatus,
      isProductVerifiedRequired: isProductVerifiedRequired
    };
    sendProductVerifyRequest(id, params)
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          setRefreshComp(true);
          setTimeout(() => {
            setRefreshComp(false);
          }, 100);
        } else {
          toast.error(data.response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const handleManufacturerChange = (data) => {
    setSelectManufacturer(data);
  };

  const getAllManufacturerApi = async () => {
    try {
      getAllManufacturer()
        .then((data) => {
          if (data.success) {
            setManufacturerList(data.data);
          } else {
            toast.error(data.response.data.message);
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="Ecommerce"
        title="Products List"
        mainTitle="Products List"
      />
      <Container fluid={true}>
        <Row className="product-card">
          <Col md="12" className="product-list">
            <Card>
              <Row>
                {/* {localStorage.getItem("role") === "seller" ? ( */}
                {localStorage.getItem("role") === "seller" ? ( 
                  <>
                    <Col md="6">
                      <Label>Brand</Label>
                      <Select
                        onChange={handleManufacturerChange}
                        options={manufacturerList?.map((d) => ({
                          value: d?.id,
                          label: d.name,
                        }))}
                        value={selectManufacturer}
                      ></Select>
                    </Col>
                    <Col md="6">
                      <div className="text-end" style={{ marginTop: 25 }}>
                        <span>
                          <Btn
                            attrBtn={{
                              color: "success",
                              type: "button",
                              onClick: () => {
                                selectedProducts.map((d) => {
                                  let prodId = d.id;
                                  let isProductVerifiedRequired =
                                    d.isProductVerifiedRequired;
                                  console.log(
                                    isProductVerifiedRequired,
                                    prodId,
                                    "this is data"
                                  );
                                  sendApproveRequestApi(
                                    prodId,
                                    isProductVerifiedRequired,
                                    isProductVerifiedRequired
                                      ? "pending"
                                      : "approve"
                                  );
                                });
                              },
                            }}
                          >
                            Send request
                          </Btn>
                        </span>
                      </div>
                    </Col>
                  </>
                ) : (
                  <div className="text-end">
                    <Link
                      className="btn btn-primary"
                      style={{ color: "white" }}
                      to={`${process.env.PUBLIC_URL}/add-product`}
                    >
                      {" "}
                      <PlusCircle />
                      {CreateNewProduct}
                    </Link>
                  </div>
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {/* <HeaderCard title={ProductListTitle} span1={ProductListDesc} /> */}
              <CardBody>
                {/* {localStorage.getItem("role") === "seller" ? ( */}
                {localStorage.getItem("role") === "seller" ? (
                  <DistributorProductTableData
                    manufacturerId={selectManufacturer}
                    onProductSelect={(items) => {
                      setSelectedProducts(items);
                    }}
                    refreshComp={refreshComp}
                  />
                ) : (
                  <ProductTableData />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ProductListContain;
