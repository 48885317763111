import React from "react";
import { useState, useEffect } from "react";
import { Btn } from "../../../../AbstractElements";

const TableCustomPagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  onItemsPerPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [displayRange, setDisplayRange] = useState({
    start: 0,
    end: 0,
  });

  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage + 1;
    const end = Math.min(currentPage * itemsPerPage, totalItems);
    setDisplayRange({ start, end });
  }, [currentPage, itemsPerPage, totalItems]);

  const handlePageChange = (newPage) => {
    onPageChange(newPage);
  };

  const handleItemsPerPageChange = (e) => {
    console.log("e.target.value= ",e.target.value)
    const newItemsPerPage = parseInt(e.target.value, 10);
    console.log("newItemsPerPage= ",newItemsPerPage)
    onItemsPerPageChange(newItemsPerPage,1);
  };

  return (
    <div className="custom-pagination">
      <div className="tbl_per_page">
        <select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className="form-select"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
        </select>
        Rows per page
      </div>
      <div className="tbl_pagination">
        <span>
          {displayRange.start}-{displayRange.end} of {totalItems}
        </span>
        <Btn
          attrBtn={{
            type: "button",
            onClick: () => handlePageChange(1),
            disabled: currentPage === 1,
          }}
        >
          {"<<"}
        </Btn>
        <Btn
          attrBtn={{
            type: "button",
            onClick: () => handlePageChange(Math.max(currentPage - 1, 1)),
            disabled: currentPage === 1,
          }}
        >
          {"<"}
        </Btn>
        {/* <span>{currentPage}</span> */}
        <Btn
          attrBtn={{
            type: "button",
            onClick: () => handlePageChange(Math.min(currentPage + 1, totalPages)),
            disabled: currentPage === totalPages,
          }}
        >
          {">"}
        </Btn>
        <Btn
          attrBtn={{
            type: "button",
            onClick: () => handlePageChange(totalPages),
            disabled: currentPage === totalPages,
          }}
        >
          {">>"}
        </Btn>
      </div>
    </div>
  );
};

export default TableCustomPagination;
