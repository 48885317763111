import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { Btn } from "../../../../AbstractElements";
import Select from "react-select";
import { getBrandByCategory, getProductCategory } from "../../../../helpers";
import { ToastContainer, toast } from "react-toastify";
import {
  addProduct,
  editProduct,
  getAllManufacturer,
  getGtin,
} from "../../../../helpers/service";
import moment from "moment";
import { useLocation, useNavigate } from "react-router";
import CustomizerContext from "../../../../_helper/Customizer";

const Newproduct = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const [productCategories, setProductCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState(null);
  const [productBrands, setProductBrands] = useState([]);
  const [selectBrand, setSelectBrand] = useState("");
  const [productName, setProductName] = useState("");
  const [productCode, setProductCode] = useState("");
  const [variant, setVariant] = useState("");
  const [gst, setGst] = useState("");
  const [description, setDescription] = useState("");
  const [additionalInfo, setAdditionInfo] = useState("");
  const [quantityForSale, setQuantityForSale] = useState("");
  const [image, setImage] = useState("");
  const [price, setPrice] = useState("");
  const [color, setColor] = useState("");
  const [weight, setWeight] = useState("");
  const [date, setDate] = useState("");
  const [productSafety, setProductSafety] = useState("");
  const [productIngredient, setProductIngredient] = useState("");
  const [sellOnLrx, setSellOnLrx] = useState(false);
  const [returnPolicy, setReturnPolicy] = useState("");
  const [country, setCountry] = useState("");
  const [manufacturerList, setManufacturerList] = useState([]);
  const [selectManufacturer, setSelectManufacturer] = useState("");
  let [loader, setLoader] = useState(false);

  const [isProductVerifiedRequired, setIsProductVerifiedRequired] =
    useState(false);
  const [isCustomGtin, setIsCustomGtin] = useState(false);
  // let errors = {
  //   productName: '1'
  // };

  const [errors, setErrors] = useState({
    productName: "",
    gst: "",
    description: "",
    category: "",
    brand: "",
    color: "",
    weight: "",
    productIngredient: "",
    country: "",
    price: "",
    manufacturer: "",
  });
  const navigation = useNavigate();
  let location = useLocation();
  const productNameSectionRef = useRef(null);
  const gstSectionRef = useRef(null);
  const descriptionSectionRef = useRef(null);
  const categorySectionRef = useRef(null);
  const brandSectionRef = useRef(null);
  const colorSectionRef = useRef(null);
  const weightSectionRef = useRef(null);
  const productIngredientSectionRef = useRef(null);
  const countrySectionRef = useRef(null);
  const priceSectionRef = useRef(null);
  let validation = () => {
    var isValid = true;
    const newErrors = { ...errors };
    if (sellOnLrx == true && price.length == 0) {
      newErrors.price = "Please enter price";
      priceSectionRef.current
        .querySelector("input")
        .classList.add("error-border");
      isValid = false;
    }
    if (productName.length == 0) {
      // toast.error("Please enter product name");
      newErrors.productName = "Please enter product name";
      // setErrors({
      //   ...errors,
      //   productName: "Please enter product name",
      // });
      if (productNameSectionRef.current) {
        // productNameSectionRef.current.scrollIntoView({ behavior: "smooth" });
        productNameSectionRef.current
          .querySelector("input")
          .classList.add("error-border");
      }
      isValid = false;
    }
    if (gst.length == 0) {
      // setErrors({
      //   ...errors,
      //   gst: "Please enter GTIN",
      // });
      newErrors.gst = "Please enter GTIN";
      if (gstSectionRef.current) {
        // gstSectionRef.current.scrollIntoView({ behavior: "smooth" });
        gstSectionRef.current
          .querySelector("input")
          .classList.add("error-border");
      }
      isValid = false;
    }
    if (description.length == 0) {
      // setErrors({
      //   ...errors,
      //   description: "Please enter description",
      // });
      newErrors.description = "Please enter description";
      if (descriptionSectionRef.current) {
        // descriptionSectionRef.current.scrollIntoView({ behavior: "smooth" });
        descriptionSectionRef.current
          .querySelector("textarea")
          .classList.add("error-border");
      }
      // toast.error("Please enter description");
      isValid = false;
    }
    if (selectCategory == null) {
      // setErrors({
      //   ...errors,
      //   category: "Please select category",
      // });
      newErrors.category = "Please enter category";
      if (categorySectionRef.current) {
        // categorySectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
      // toast.error("Please select category");
      isValid = false;
    }
    // if (selectBrand.length == 0) {
    //   if (selectCategory == null) {
    //     setErrors({
    //       ...errors,
    //       brand: "Please select category first.",
    //     });
    //     newErrors.brand = "Please select category first.";
    //   } else {
    //     // setErrors({
    //     //   ...errors,
    //     //   brand: "Please select brand",
    //     // });
    //     newErrors.brand = "Please select brand.";
    //   }
    //   if (brandSectionRef.current) {
    //     // brandSectionRef.current.scrollIntoView({ behavior: "smooth" });
    //   }
    //   // toast.error("Please select brand");
    //   isValid = false;
    // }
    //  else if (price.length == 0) {
    //   toast.error("Please enter price");
    //   return false;
    // }
    if (color.length == 0) {
      // toast.error("Please enter color");
      // setErrors({
      //   ...errors,
      //   color: "Please enter color",
      // });
      newErrors.color = "Please enter color.";
      colorSectionRef.current
        .querySelector("input")
        .classList.add("error-border");
      isValid = false;
    }
    if (weight.length == 0) {
      // toast.error("Please enter weight");
      // setErrors({
      //   ...errors,
      //   weight: "Please enter weight",
      // });
      newErrors.weight = "Please enter weight.";
      weightSectionRef.current
        .querySelector("input")
        .classList.add("error-border");
      isValid = false;
    }
    if (productIngredient.length == 0) {
      // toast.error("Please enter product Ingredient");
      // setErrors({
      //   ...errors,
      //   productIngredient: "Please enter keywords",
      // });
      newErrors.productIngredient = "Please enter keywords.";
      productIngredientSectionRef.current
        .querySelector("textarea")
        .classList.add("error-border");
      isValid = false;
    }
    if (country.length == 0) {
      // toast.error("Please enter country origin");
      // setErrors({
      //   ...errors,
      //   country: "Please enter country.",
      // });
      newErrors.country = "Please enter country.";
      countrySectionRef.current
        .querySelector("input")
        .classList.add("error-border");
      isValid = false;
    }
    console.log("selectManufacturer = ", selectManufacturer);
    console.log("newErrors = ", newErrors);
    if (!localStorage.getItem("manufacturerId")) {
      if (!selectManufacturer.value) {
        newErrors.manufacturer = "Please select";
        isValid = false;
      }
    }
    setErrors(newErrors);
    return isValid;
  };
  const onSubmit = () => {
    if (validation()) {
      setLoader(true);
      let params = {
        name: productName,
        tax: gst,
        variant: variant,
        category: selectCategory?.value,
        // brand: selectBrand?.value,
        description: description,
        sellOnLRX: sellOnLrx,
        expirationDate: date,
        price: price ? parseFloat(price) : "",
        color: color,
        weight: weight,
        ingredients: productIngredient.trim().split(","),
        countryOrigin: country,
        safetyInfo: productSafety,
        returnPolicy: returnPolicy,
        additionalInfo: additionalInfo,
        quantityForSale: quantityForSale,
        // productCode: productCode,
        productImage: image,
        manufacturerId: selectManufacturer?.value
          ? selectManufacturer?.value
          : localStorage.getItem("manufacturerId"),
        isProductVerifiedRequired: isProductVerifiedRequired,
        isCustomTax: isCustomGtin,
        isProductCreatedByManufacturer: selectManufacturer?.value
          ? false
          : true,
        productStatus: "notAdded",
      };
      console.log("params = ", params);
      if (location.state == null) {
        addProduct(params)
          .then((data) => {
            if (data?.success) {
              navigation("/product-list");
              toast.success(data.message);
            } else {
              setTimeout(() => {
                setLoader(false);
              }, 1000);
              toast.error(data.response.data.message);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoader(false);
            }, 1000);
            toast.error(err);
          });
      } else {
        editProduct(params, location.state?._id)
          .then((data) => {
            console.log("data = ", data);
            if (data?.success) {
              navigation("/product-list");
              toast.success(data.message);
            } else {
              setTimeout(() => {
                setLoader(false);
              }, 1000);
              toast.error(data.response.data.message);
            }
          })
          .catch((err) => {
            console.log("err = ", err);
            setTimeout(() => {
              setLoader(false);
            }, 1000);
            console.log(err, "this is error");
          });
      }
    }
  };

  useEffect(() => {
    getData();
    if (location.state != null) {
      setDefaultValue();
    }
  }, []);
  let getData = async () => {
    try {
      await getAllCategoryApi();
      await getAllManufacturerApi();
    } catch (e) {
      console.log(e.message, "error from getData");
      toast.error(e.message);
    }
  };
  let setDefaultValue = () => {
    let data = location.state;
    console.log(data, "this is data");
    setProductName(data?.name);
    setProductCode(data?.productCode);
    setVariant(data?.variant);
    setGst(data?.tax);
    setDescription(data?.description);
    setPrice(data?.price != null ? data?.price : "");
    setColor(data?.color);
    setWeight(data?.weight);
    setDate(data?.expirationDate + "");
    setProductSafety(data?.safetyInfo);
    setAdditionInfo(data?.additionalInfo);
    setProductIngredient(data?.ingredients + "");
    setSellOnLrx(data?.sellOnLRX);
    setReturnPolicy(data?.returnPolicy);
    setQuantityForSale(
      data?.quantityForSale == null ? "" : data?.quantityForSale
    );
    setCountry(data?.countryOrigin);
    setIsProductVerifiedRequired(data?.isProductVerifiedRequired);
    setIsCustomGtin(data?.isCustomTax);
  };
  const getAllCategoryApi = () => {
    getProductCategory().then((data) => {
      if (data.success) {
        setProductCategories(data.data);
        if (location.state != null) {
          let alreadySelectedCategory = data.data.filter(
            (catData) => catData._id == location.state?.category
          )[0];

          getBrandApi(alreadySelectedCategory._id);
          setSelectCategory({
            value: alreadySelectedCategory._id,
            label: alreadySelectedCategory.name,
          });
        }
      } else {
        toast.error(data.response.data.message);
      }
    });
  };

  const getAllManufacturerApi = async () => {
    getAllManufacturer().then((data) => {
      if (data.success) {
        setManufacturerList(data.data);
        if (location.state != null) {
          let alreadySelectedManufacturer = data.data.filter(
            (catData) => catData.id == location.state?.manufacturerId
          )[0];
          setSelectManufacturer({
            value: alreadySelectedManufacturer?.id,
            label: alreadySelectedManufacturer?.name,
          });
        }
      } else {
        toast.error(data.response.data.message);
      }
    });
  };
  const getBrandApi = async (id) => {
    try {
      getBrandByCategory(id)
        .then((data) => {
          console.log("getBrandByCategory", data);
          if (data.success) {
            console.log(data, "this is data");
            setProductBrands(data.data);
            if (location.state != null) {
              let alreadySelectedBrand = data.data.filter(
                (brandData) => brandData._id == location.state?.brand
              )[0];
              setSelectBrand({
                value: alreadySelectedBrand._id,
                label: alreadySelectedBrand.name,
              });
            }
          } else {
            toast.error(data.response.data.message);
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    } catch (error) {
      toast.error(error);
    }
  };

  const handleCategoryChange = (data) => {
    setSelectCategory(data);
    setErrors({
      ...errors,
      category: "",
    });
    setSelectBrand("");
    getBrandApi(data?.value);
  };

  const handleBrandChange = (data) => {
    setSelectBrand(data);
    setErrors({
      ...errors,
      brand: "",
    });
  };

  const handleManufacturerChange = async (data) => {
    setGst("");
    setIsCustomGtin(false);
    // const gtinResp = await getGtin(data.value);
    // console.log("gtinResp = ", gtinResp);
    // if (gtinResp.data != null) {
    //   setGst(gtinResp.data);
    // }
    setSelectManufacturer(data);
    setErrors({
      ...errors,
      manufacturer: "",
    });
  };
  const handleCheckboxChange = (event) => {
    setIsProductVerifiedRequired(event.target.checked);
  };
  const handleChangeCustomGtin = async (event) => {
    // setIsCustomGtin(event.target.checked);
    if (localStorage.getItem("role") == "manufacturer") {
      setIsCustomGtin(event.target.checked);
      console.log(
        "-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=",
        localStorage.getItem("manufacturerId")
      );
      if (event.target.checked == true) {
        setGst("");
        const gtinResp = await getGtin(localStorage.getItem("manufacturerId"));
        if (gtinResp.data != null) {
          setGst(gtinResp.data);
        }
      }
    } else if (localStorage.getItem("role") == "admin") {
      console.log("-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=", selectManufacturer.value);
      if (selectManufacturer.value == null) {
        toast.error("Plase select brand first.");
      } else {
        setIsCustomGtin(event.target.checked);
        if (event.target.checked == true) {
          setGst("");
          const gtinResp = await getGtin(selectManufacturer.value);
          if (gtinResp.data != null) {
            setGst(gtinResp.data);
          }
        }
      }
    }
    // console.log(
    //   "selectManufacturer = ",
    //   selectManufacturer?.value
    //     ? selectManufacturer?.value
    //     : localStorage.getItem("manufacturerId")
    // );
  };

  const LRXSellOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const handleSellOnLrxChange = (event) => {
    setSellOnLrx(event.target.checked);
    if (event.target.checked == false) {
      setErrors({
        ...errors,
        price: "",
      });
    }
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="Ecommerce"
        title={location.state != null ? "Edit Product" : "Add New Product"}
        mainTitle={location.state != null ? "Edit Product" : "Add New Product"}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="theme-form" id="add_product_form">
                  {/* <Form className="theme-form" id="add_product_form"> */}
                  <Row>
                    <Col sm="6">
                      <div ref={productNameSectionRef}>
                        <FormGroup>
                          <Label for="product_name">Product Name</Label>
                          <Input
                            className="form-control"
                            type="text"
                            name="product_name"
                            placeholder="Enter Product Name"
                            onChange={(e) => {
                              setProductName(e.target.value);
                              setErrors({
                                ...errors,
                                ["productName"]: "",
                              });
                              productNameSectionRef.current
                                .querySelector("input")
                                .classList.remove("error-border");
                            }}
                            id="product_name"
                            value={productName}
                          />
                          <span className="d-block feild-error">
                            {errors.productName}
                          </span>
                          <div className="valid-feedback">Looks good!</div>
                        </FormGroup>
                      </div>
                    </Col>
                    <Col md="6">
                      <FormGroup className="m-t-15 custom-radio-ml">
                        <div className="checkbox checkbox-primary">
                          <Input
                            id="checkbox-primary-1"
                            type="checkbox"
                            checked={isProductVerifiedRequired}
                            onChange={handleCheckboxChange}
                          />
                          <Label for="checkbox-primary-1">
                            Product verification required
                          </Label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <div ref={gstSectionRef}>
                        <FormGroup>
                          <Label>Global Trade Item Number(GTIN)</Label>
                          <Input
                            className={`form-control ${
                              location.state != null || isCustomGtin == true
                                ? "disable-input"
                                : ""
                            }`}
                            type="text"
                            name="product_gtin"
                            placeholder="Please enter Global Trade Item Number(GTIN)"
                            onChange={(e) => {
                              setGst(e.target.value);
                              setErrors({
                                ...errors,
                                ["gst"]: "",
                              });
                              gstSectionRef.current
                                .querySelector("input")
                                .classList.remove("error-border");
                            }}
                            disabled={
                              location.state != null || isCustomGtin == true
                            }
                            value={gst}
                          />
                          <span className="d-block feild-error">
                            {errors?.gst}
                          </span>
                        </FormGroup>
                      </div>
                    </Col>
                    {location.state == null && (
                      <Col md="6">
                        <FormGroup className="m-t-15 custom-radio-ml">
                          <div className="checkbox checkbox-primary">
                            <Input
                              id="checkbox-primary-3"
                              type="checkbox"
                              checked={isCustomGtin}
                              onChange={handleChangeCustomGtin}
                            />
                            <Label for="checkbox-primary-3">Custom GTIN</Label>
                          </div>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col md="6">
                      <div ref={categorySectionRef}>
                        <FormGroup>
                          <Label>Category</Label>
                          <Select
                            options={productCategories?.map((d) => ({
                              value: d?._id,
                              label: d.name,
                            }))}
                            onChange={handleCategoryChange}
                            value={selectCategory}
                          ></Select>
                          <span className="d-block feild-error">
                            {errors.category}
                          </span>
                        </FormGroup>
                      </div>
                    </Col>

                    {localStorage.getItem("role") != "manufacturer" ? (
                      <Col md="6">
                        <FormGroup>
                          <Label>Brand</Label>
                          <Select
                            onChange={handleManufacturerChange}
                            options={manufacturerList?.map((d) => ({
                              value: d?.id,
                              label: d.name,
                              gtinFormat: d?.gtinFormat,
                            }))}
                            value={selectManufacturer}
                          ></Select>
                          <span className="d-block feild-error">
                            {errors.manufacturer}
                          </span>
                        </FormGroup>
                      </Col>
                    ) : null}
                    {/* <Col md="6">
                      <div ref={brandSectionRef}>
                        <FormGroup>
                          <Label>Brand</Label>
                          {selectCategory ? (
                            <Select
                              onChange={handleBrandChange}
                              options={productBrands?.map((d) => ({
                                value: d?._id,
                                label: d.name,
                              }))}
                              value={selectBrand}
                            ></Select>
                          ) : (
                            <Select
                              value={{
                                value: "initial",
                                label: "Please select category first.",
                              }}
                            ></Select>
                          )}
                          <span className="d-block feild-error">
                            {errors.brand}
                          </span>
                        </FormGroup>
                      </div>
                    </Col> */}
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="product_price">Quantity for Sale</Label>
                        <InputGroup>
                          <Input
                            id="product_quantity_for_sale"
                            type="text"
                            placeholder="Please enter quantity for sale"
                            className={`form-control`}
                            name="product_price"
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              // Check if the input is a valid decimal number
                              const isValidDecimal = /^\d+(\.\d*)?$/.test(
                                inputValue
                              );
                              // Update state only if the input is valid or if it's an empty string
                              if (isValidDecimal || inputValue === "") {
                                setQuantityForSale(inputValue);
                              }
                            }}
                            value={quantityForSale}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup className="m-t-15 custom-radio-ml">
                        <div className="checkbox checkbox-primary">
                          <Input
                            id="checkbox-primary-2"
                            type="checkbox"
                            checked={sellOnLrx}
                            onChange={handleSellOnLrxChange}
                          />
                          <Label for="checkbox-primary-2">Sell on LRX</Label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <div ref={priceSectionRef}>
                        <FormGroup>
                          <Label for="product_price">Price</Label>
                          <InputGroup>
                            <InputGroupText>{"$"}</InputGroupText>
                            <Input
                              disabled={!sellOnLrx}
                              id="product_price"
                              type="text"
                              placeholder="Price"
                              className={`form-control ${
                                !sellOnLrx ? "disable-input" : ""
                              }`}
                              name="product_price"
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                // Check if the input is a valid decimal number
                                const isValidDecimal = /^\d+(\.\d*)?$/.test(
                                  inputValue
                                );
                                // Update state only if the input is valid or if it's an empty string
                                if (isValidDecimal || inputValue === "") {
                                  setPrice(inputValue);
                                  setErrors({
                                    ...errors,
                                    price: "",
                                  });
                                  priceSectionRef.current
                                    .querySelector("input")
                                    .classList.remove("error-border");
                                }
                              }}
                              value={price + ""}
                            />

                            <div className="valid-feedback">Looks good!</div>
                          </InputGroup>
                        </FormGroup>
                      </div>
                      <span className="d-block feild-error">
                        {errors.price}
                      </span>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <Label for="upload_product_image">
                          Upload Product Image
                        </Label>
                        <Input
                          style={{ paddingLeft: "1rem" }}
                          className="form-control "
                          id="product_color"
                          type="file"
                          name="upload_product_image"
                          placeholder="upload_product_image"
                          onChange={(e) => {
                            setImage(e.target.files[0]);
                          }}
                        />
                        <span className="d-block">
                          {errors?.upload_product_image}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <div ref={weightSectionRef}>
                        <FormGroup>
                          <Label for="product_weight">Weight</Label>
                          <Input
                            className="form-control"
                            id="product_weight"
                            type="text"
                            name="product_weight"
                            placeholder="Ex.50g"
                            onChange={(e) => {
                              setWeight(e.target.value);
                              setErrors({ ...errors, weight: "" });
                              weightSectionRef.current
                                .querySelector("input")
                                .classList.remove("error-border");
                            }}
                            value={weight}
                          />
                          <span className="d-block feild-error">
                            {errors.weight}
                          </span>
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col md="3">
                      <FormGroup>
                        <Label for="product_sell">Sell on LRX</Label>
                        <Input
                          className="form-control"
                          id="product_sell"
                          type="text"
                          name="product_sell"
                          placeholder="Ex.Yes"
                          onChange={(e) => {
                            setSellOnLrx(e.target.value);
                          }}
                          value={sellOnLrx}
                        />
                        <span className="d-block">{errors?.product_sell}</span>
                      </FormGroup>
                    </Col> */}
                    <Col md="3">
                      <div ref={colorSectionRef}>
                        <FormGroup>
                          <Label for="product_color">Color</Label>
                          <Input
                            className="form-control"
                            id="product_color"
                            type="text"
                            name="product_color"
                            placeholder="Add Colors"
                            onChange={(e) => {
                              setColor(e.target.value);
                              setErrors({ ...errors, color: "" });
                              colorSectionRef.current
                                .querySelector("input")
                                .classList.remove("error-border");
                            }}
                            value={color}
                          />
                          <span className="d-block feild-error">
                            {errors.color}
                          </span>
                        </FormGroup>
                      </div>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label for="product_ex_date">Expiration Date</Label>
                        <Input
                          className="form-control"
                          id="product_ex_date"
                          type="text"
                          name="product_ex_date"
                          placeholder="Ex.+36 Months"
                          onChange={(e) => {
                            setDate(e.target.value);
                          }}
                          value={date}
                        />
                        <span className="d-block">
                          {errors?.product_ex_date}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <div ref={countrySectionRef}>
                        <FormGroup>
                          <Label for="product_country_origin">
                            Country of Origin
                          </Label>
                          <Input
                            className="form-control"
                            id="product_country_origin"
                            type="text"
                            name="product_country_origin"
                            placeholder="Enter Country"
                            onChange={(e) => {
                              setCountry(e.target.value);
                              setErrors({
                                ...errors,
                                country: "",
                              });
                              countrySectionRef.current
                                .querySelector("input")
                                .classList.remove("error-border");
                            }}
                            value={country}
                          />
                          <span className="d-block feild-error">
                            {errors.country}
                          </span>
                        </FormGroup>
                      </div>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label for="product_return_policy">Return Policy</Label>
                        <Input
                          className="form-control"
                          id="product_return_policy"
                          type="text"
                          name="product_return_policy"
                          placeholder="Ex.No Returns"
                          onChange={(e) => {
                            setReturnPolicy(e.target.value);
                          }}
                          value={returnPolicy}
                        />

                        <span className="d-block">
                          {errors?.product_return_policy}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <div ref={productIngredientSectionRef}>
                        <FormGroup>
                          <Label for="product_ingredients">Keywords</Label>
                          <Input
                            type="textarea"
                            className="form-control"
                            rows="3"
                            name="product_ingredients"
                            id="product_ingredients"
                            placeholder="Please enter keywords with comma seperated. (Ex. skin, herbal)"
                            onChange={(e) => {
                              setProductIngredient(e.target.value);
                              setErrors({
                                ...errors,
                                productIngredient: "",
                              });
                              productIngredientSectionRef.current
                                .querySelector("textarea")
                                .classList.remove("error-border");
                            }}
                            value={productIngredient}
                          />
                          <span className="d-block feild-error">
                            {errors.productIngredient}
                          </span>
                        </FormGroup>
                      </div>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="product_safery_info">Safety info</Label>
                        <Input
                          type="textarea"
                          id="product_safery_info"
                          className="form-control"
                          rows="3"
                          name="product_safery_info"
                          placeholder="Please enter safety information"
                          onChange={(e) => {
                            setProductSafety(e.target.value);
                          }}
                          value={productSafety}
                        />
                        <span className="d-block">
                          {errors?.product_safery_info &&
                            "Product Description is required"}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <div ref={descriptionSectionRef}>
                        <FormGroup>
                          <Label>Description</Label>
                          <Input
                            type="textarea"
                            placeholder="Please enter product description"
                            className="form-control"
                            rows="3"
                            name="product_description"
                            onChange={(e) => {
                              setDescription(e.target.value);
                              setErrors({
                                ...errors,
                                ["description"]: "",
                              });
                              descriptionSectionRef.current
                                .querySelector("textarea")
                                .classList.remove("error-border");
                            }}
                            value={description}
                          />
                          <span className="d-block feild-error">
                            {errors.description}
                          </span>
                        </FormGroup>
                      </div>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Additional Info</Label>
                        <Input
                          type="textarea"
                          placeholder="Please enter additional information"
                          className="form-control"
                          rows="3"
                          name="product_additional_info"
                          onChange={(e) => {
                            setAdditionInfo(e.target.value);
                          }}
                          value={additionalInfo}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      onClick: onSubmit,
                      disabled: loader,
                    }}
                  >
                    {loader ? (
                      <Spinner size="sm">Loading...</Spinner>
                    ) : location.state != null ? (
                      "Save"
                    ) : (
                      "Add Product"
                    )}
                  </Btn>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Newproduct;
