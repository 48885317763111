
import React, {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import { deleteDistributor, getAllDistributor } from "../../../../helpers";
import { toast } from "react-toastify";
import { Btn, H6, Image } from "../../../../AbstractElements";
import { useNavigate, useLocation } from "react-router";
import CustomizerContext from "../../../../_helper/Customizer";
import Loader from "../../../../Layout/Loader";
import { Search } from "react-feather";
import SweetAlert from 'sweetalert2';
import TableCustomPagination from "../../Ecommerce/ProductList/TableCustomPagination";
import { deleteBrand, getAllBrands, getVerifyProductHistory } from "../../../../helpers/service";
import { brandColumns, historyColumns } from "../../../../Data/ManufacturerDistributor/BrandList";

const style = {
  width: 40,
  height: 40,
};
const style2 = { width: 60, fontSize: 14, padding: 4 };

const HistoryTableData = () => {
  const [historyData, setBrandData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();

  useEffect(() => {
    setLoading(true);
    getVerifyProductHistoryApi();
  }, []);

  const getVerifyProductHistoryApi = () => {
    try {
      getVerifyProductHistory()
        .then((data) => {
          console.log("data= ", data);
          if (data.success) {
            let newData = data.data.map((d,index) => {
              index += 1; 
              const timestampDate = new Date(d.latestTimestamp);

              // Format the date as DD-MM-YYYY
              const formattedTimestamp = timestampDate.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              });
              return {
                Id: index, 
                userName: <H6>{d.userName}</H6>,
                productName: <b>{d.productName}</b>,
                totalCount:<b>{d.totalCount}</b>,
                latestTimestamp: <b>{formattedTimestamp}</b>,
                action: (
                  <div>
                    <span>
                      <Btn
                        attrBtn={{
                          style: style2,
                          color: "success",
                          className: "btn btn-xs",
                          type: "button",
                          onClick: () => onPressEdit(d),
                        }}
                      >
                        View{" "}
                      </Btn>
                    </span>
                  </div>
                ),
              };
            });
            setBrandData(newData);
            setLoading(false);
          } else {
            toast.error(data.response.data.message);
          }
        })
        .catch((error) => {
          toast.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };

  let onPressEdit = (data) => {
    navigation(`/history/view`, {
      state: data,
    });
  };
  return (
    <Fragment>
      <div className="table-responsive history-table">
        {loading ? (
          // <Loader />
          <Suspense fallback={<Loader />}></Suspense>
        ) : (
          <>
            <DataTable
              noHeader
              columns={historyColumns}
              data={historyData}
              highlightOnHover={true}
              striped={true}
              responsive={true}
            />
          </>
        )}
      </div>
    </Fragment>
  );
};
export default HistoryTableData;
