import React, { Fragment, useContext, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { Btn } from "../../../../AbstractElements";
import Select from "react-select";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import CustomizerContext from "../../../../_helper/Customizer";
import { getProductCategory } from "../../../../helpers";
import { Loader } from "react-feather";
import { addBrand, editBrand } from "../../../../helpers/service";

const AddBrand = () => {
  const navigation = useNavigate();
  let location = useLocation();
  const [name, setName] = useState("");
  let [loader, setLoader] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState(null);
  let validation = () => {

    if (name.length === 0) {
      toast.error("Please enter a name.");
      return false;
    }
    if(selectCategory == null || selectCategory?.value == ""){
      toast.error("Please select category.");
      return false;
    }

    return true;
  };

  const onSubmit = () => {
    if (validation()) {
      setLoader(true);
      let params = {
        name: name,
        category: selectCategory?.value        
      };
      if (location.state == null) {
        addBrand(params)
          .then((data) => {
            console.log("data::", data);
            if (data?.success) {
              toast.success(data.message);
              setTimeout(() => {
                navigation("/brands/brand-list");
              }, 1000);
            } else {
              setTimeout(() => {
                setLoader(false);
              }, 1000);
              toast.error(data.response.data.message);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoader(false);
            }, 1000);
            toast.error(err);
          });
      }
       else {
        let editParams = {
          name: name,
          category: selectCategory?.value
        };
        console.log("editParams = ",editParams)
        editBrand(editParams, location.state?._id)
          .then((data) => {
            if (data?.success) {
              navigation("/brands/brand-list");
              toast.success(data.message);
            } else {
              setTimeout(() => {
                setLoader(false);
              }, 1000);
              toast.error(data.response.data.message);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoader(false);
            }, 1000);
            toast.error(err);
          });
      }
    }
  };

  useEffect(() => {
    getData();
    if (location.state != null) {
      setDefaultValue();
    }
  }, []);

  let getData = async () => {
    try {
      await getAllCategoryApi();
    } catch (e) {
      console.log(e.message, "error from getData");
      toast.error(e.message);
    }
  };

  const getAllCategoryApi = async () => {
    await getProductCategory().then((data) => {
      if (data.success) {
        setProductCategories(data.data);
        if (location.state != null) {
          let alreadySelectedCategory = data.data.filter(
            (catData) => catData._id == location.state?.category
          )[0];
          setSelectCategory({
            value: alreadySelectedCategory._id,
            label: alreadySelectedCategory.name,
          });
        }
      } else {
        toast.error(data.response.data.message);
      }
    });
  };
  let setDefaultValue = () => {
    let data = location.state;
    console.log("-=-=-=",data);
    setName(data.name);
  };

  const handleCategoryChange = (data) => {
    setSelectCategory(data);
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="Brands"
        title={location.state != null ? "Edit Brand" : "Add New Brand"}
        mainTitle={location.state != null ? "Edit Brand" : "Add New Brand"}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {/* <Form className='theme-form' id="add_product_form" noValidate='' onSubmit={handleSubmit(onSubmit)}> */}
                <Form className="theme-form" id="add_product_form">
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label for="distributor_name">Name</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="distributor_name"
                          placeholder="Enter Brand Name"
                          id="distributor_name"
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          value={name}
                        />

                        <div className="valid-feedback">Looks good!</div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Col md="6">
                      <FormGroup>
                        <Label>Category</Label>
                        <Select
                          options={productCategories?.map((d) => ({
                            value: d?._id,
                            label: d.name,
                          }))}
                          onChange={handleCategoryChange}
                          value={selectCategory}
                        ></Select>
                      </FormGroup>
                    </Col>

                  <Btn
                    attrBtn={{
                      color: "primary",
                      onClick: onSubmit,
                      disabled: loader,
                    }}
                  >
                    {loader ? (
                      <Spinner size="sm">Loading...</Spinner>
                    ) : location.state != null ? (
                      "Save"
                    ) : (
                      "Add Brand"
                    )}
                  </Btn>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddBrand;
