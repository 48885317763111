import React, { Fragment, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { contactUsSendEmail } from "../../../helpers/service";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    question: "",
    dropdownOpen: false,
  });
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  
  const { name, email, phone, message, question } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleQuestionChange = (selectedOption) => {
    setFormData({ ...formData, question: selectedOption });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      const passData = {
        name,
        email,
        phone,
        message,
        question: question.value,
      };
      setLoader(true);
      contactUsSendEmail(passData)
        .then((response) => {
          setLoader(false);
          toast.success(
            "Message has been sent successfully! We will reach you out soon."
          );
          setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
            question: "",
          });
          setErrors({});
        })
        .catch((error) => {
          setLoader(false);
          console.log("error = ", error);
          toast.error(
            "An error occurred while sending your message. Please try again later."
          );
        });
    } else {
      setErrors(errors);
    }
  };

  const validateForm = (formData) => {
    const errors = {};
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (formData.phone && !isValidPhone(formData.phone)) {
      errors.phone = "Invalid phone number format";
    }
    return errors;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  return (
    <Fragment>
      <Container fluid className="p-0">
        <Row className="justify-content-center mt-5 align-items-center">
          <Col md={6}>
            <h2 className="text-center mb-4 mt-5">Contact Us</h2>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter your name"
                  value={name}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <span className="text-danger">{errors.email}</span>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="phone">Phone Number</Label>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={handleChange}
                />
                {errors.phone && (
                  <span className="text-danger">{errors.phone}</span>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="message">Message</Label>
                <Input
                  type="textarea"
                  name="message"
                  id="message"
                  placeholder="Enter your message"
                  value={message}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="question">Question</Label>
                <Select
                  onChange={handleQuestionChange}
                  options={[
                    {
                      value: "How can I contact support?",
                      label: "How can I contact support?",
                    },
                    {
                      value: "What are your working hours?",
                      label: "What are your working hours?",
                    },
                    {
                      value: "How do I return a product?",
                      label: "How do I return a product?",
                    },
                    // Add more options as needed
                  ]}
                  value={question}
                />
              </FormGroup>
              {loader ? (
                <Spinner size="sm">Loading...</Spinner>
              ) : (
                <Button color="primary" type="submit" attrBtn={{
                  color: "primary",
                  disabled: loader,
                }}>
                  Submit
                </Button>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ContactUs;
