import { useEffect, useState } from "react";
import { useLocation } from "react-router";

const HistoryDataView = () => {
  let location = useLocation();
  const [historyData, setHistoryData] = useState();

  useEffect(() => {
    let data = location.state;
    console.log("data=v", data);
    setHistoryData(data);
  }, []);

  return (
    <div>
      <h2>Product History:</h2> 
      <ul>
        {historyData?.history.map((historyItem, index) => (
          <li key={index} style={{ display: "flex"}}>
            <p>{historyData?.productName} - </p>&nbsp;&nbsp;
            <p>
             Timestamp:{" "}
              {new Date(historyItem.timestamp).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}{" "}
              {new Date(historyItem.timestamp).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HistoryDataView;
